import * as React from 'react';
import {
    Button,
    PublishStatusIndicator,
    DataTable,
    ImposiumDropdown,
    Spinner,
    AssetsTableDateCell,
    doAssetTableHydration,
    resetFilters,
    SessionService,
    getStoryPublishStatus,
    renderTooltipProps,
    renderTooltip,
    setAccessData,
    clearStoryPublishStatus,
    StoryTableTotalRendersCell
} from '@imposium-hub/components';
import { updateEditorConfig } from '../redux/actions/editor';
import { connect } from 'react-redux';
import {
    overwriteStory,
    createStory,
    deleteStory,
    checkStoryForErrors
} from '../redux/actions/story';
import { header as copy } from '../constants/copy';
import { IComposition, IStory } from '../constants/snippets';
import { IEditor } from '../redux/reducers/editor';
import { IProject } from '../redux/reducers/project';
import { IChanges } from '../redux/reducers/changes';
import { disableForEncodingSettings, generateUUID, getFirstAct } from '../util/story';
import { logError, logNotification } from '../util/notifications';
import { replaceRoute, pushRoute } from '../util/routing';
import { addViewer, saveStory } from '../redux/actions/story';
import { VIEWER_TYPES, VIEWER_LABELS } from '../constants/viewer';
import {
    ICON_COG,
    ICON_SAVE,
    ICON_VIDEOPLUS,
    ICON_PUBLISH,
    ICON_WARNING
} from '../constants/icons';
import { POST_SETTINGS_ACTIONS, PROJECT_SETTINGS_TABS } from '../constants/editor';
import { api } from '../constants/app';
import { hasVideoOutputSettings } from '../util/ui';
import _ from 'lodash';

interface ILeftHeaderProps {
    organizationId: string;
    editor: IEditor;
    story: IStory;
    project: IProject;
    changes: IChanges;
    auth: any;
    compositions: IComposition[];
    disableUnpublishedPreview?: boolean;
    updateEditorConfig(c): void;
    createStory(s: IStory): any;
    overwriteStory(i: string, s: IStory): void;
    deleteStory(i: string): void;
    addViewer(c: any): void;
    saveStory(): any;
    createTemplate(i: string, d: any): any;
    storyErrors(): any;
    errors: any;
    setAccessData(accessData: any): void;
    clearStoryPublishStatus(apiInstance: any): void;
    org: any;
    onStoryChange?: (story: any) => any;
    doAssetTableHydration(apiInstance, sId): void;
    resetFilters(): any;
    publishData: any;
    getStoryPublishStatus(apiInstance: any, sId: string): any;
    access: any;
}

interface ILeftHeaderState {
    showStoriesDropdown: boolean;
    order: string;
    orderDirection: string;
    totalRendersLoading: boolean;
}

class LeftHeader extends React.PureComponent<ILeftHeaderProps, ILeftHeaderState> {
    private evtHandlers: any;

    private importInput: any;

    private readonly storyToggleRef: any = null;

    private readonly COLUMNS: any[] = [
        {
            accessor: 'name',
            Header: 'Name',
            width: 115,
            minWidth: 115,
            disableSortBy: false
        },
        {
            accessor: 'total_renders',
            Header: 'Total Renders',
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            disableSortBy: false
        },
        {
            accessor: 'last_modified_by',
            Header: 'Last Modified By',
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            disableSortBy: true
        },
        {
            accessor: 'date_modified',
            Header: 'Last Modified',
            width: 65,
            minWidth: 65,
            maxWidth: 65,
            disableSortBy: false,
            Cell: (cell: any) => (
                <AssetsTableDateCell
                    cell={cell}
                    date_created={false}
                />
            )
        },
        {
            accessor: 'date_created',
            Header: 'Created Date',
            width: 65,
            minWidth: 65,
            maxWidth: 65,
            disableSortBy: false,
            Cell: (cell: any) => (
                <AssetsTableDateCell
                    cell={cell}
                    date_created={true}
                />
            )
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            showStoriesDropdown: false,
            order: 'date_modified',
            orderDirection: 'desc',
            totalRendersLoading: true
        };

        this.importInput = React.createRef();
        this.storyToggleRef = React.createRef();

        this.evtHandlers = {
            inputs: {
                import: (e) => this.importInputChanged(e)
            },
            buttons: {
                save: () => this.saveStoryHandler(),
                settings: () => this.openSettinsModal(),
                delete: () => this.deleteStoryClicked(),
                newComp: () => this.newStoryClicked(),
                errors: () => this.errorViewerClicked(),
                publish: () => this.openPublishWizard()
            }
        };
    }

    private saveStoryHandler() {
        const {
            editor: { reHydrateAssetTable },
            story: { id }
        } = this.props;

        this.props.saveStory().then(() => {
            this.props.getStoryPublishStatus(api, id);
            if (reHydrateAssetTable) {
                setTimeout(() => {
                    this.props.doAssetTableHydration(api, id);
                    this.props.updateEditorConfig({ reHydrateAssetTable: false });
                }, 2000);
            }
        });
    }

    private openSettinsModal() {
        this.props.updateEditorConfig({
            settingsModalOpen: true
        });
    }

    private changeStory = (story: any): void => {
        const { onStoryChange } = this.props;

        if (typeof onStoryChange === 'function') {
            onStoryChange(story);
            this.props.updateEditorConfig({ assetsTableItemsPerPage: null });
            this.props.resetFilters();
            this.setState({ showStoriesDropdown: false });
        }
    };

    private openPublishWizard() {
        const {
            project: { actId, sceneId },
            story
        } = this.props;
        const publish = () => {
            const hasSettings = hasVideoOutputSettings(actId, sceneId, story);

            if (hasSettings) {
                this.props.updateEditorConfig({
                    publishWizardOpen: true,
                    publishDataset: false
                });
            } else {
                this.props.updateEditorConfig({
                    settingsModalOpen: true,
                    postSettingsAction: POST_SETTINGS_ACTIONS.PUBLISH,
                    activeSettingsTab: PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS
                });
            }
        };

        if (this.props.changes.unsaved) {
            const check = confirm(copy.checkUnsaved);
            if (check) {
                this.props.saveStory();
                publish();
            }
        } else {
            publish();
        }
    }

    private newStoryClicked() {
        this.props.updateEditorConfig({
            newStoryModalOpen: true
        });
        this.props.clearStoryPublishStatus(api);
    }

    private importInputChanged(evt) {
        const storyFile = evt.target.files[0];
        if (storyFile) {
            const reader = new FileReader();
            reader.onload = (e) => this.storyJSONRead(e);
            reader.readAsText(evt.target.files[0]);
        }
    }

    private storyJSONRead(e) {
        const story: IStory = JSON.parse(e.target.result);

        api.getStory(story.id)
            .then((d) => {
                // story exists on account, overwrite the story JSON if the user wants to
                const conf = confirm(copy.projectOverwritePrompt);
                if (conf) {
                    this.props.overwriteStory(story.id, story);
                    pushRoute(`/${story.id}`);
                }
            })
            .catch((errorGet) => {
                // if the story doesn't exist, move forward with the import. TODO: make a better call fo this.
                if (errorGet.response.status === 404) {
                    this.props.updateEditorConfig({ loading: true });
                    this.props.createStory(story).then(() => {
                        pushRoute(`/${story.id}`);
                        this.props.updateEditorConfig({ loading: false });
                        this.props.resetFilters();
                    });
                } else {
                    logError(copy.importError);
                }
            });
    }

    private deleteStoryClicked() {
        const {
            project: { storyId },
            story: { name }
        } = this.props;
        const conf = confirm(copy.confirmDelete.replace('[storyName]', name));
        if (conf) {
            replaceRoute('/');
            this.props.deleteStory(storyId);
        }
    }

    private async errorViewerClicked() {
        const { storyErrors } = this.props;

        const errors = await storyErrors();

        if (errors.length > 0) {
            this.props.addViewer({
                type: VIEWER_TYPES.ERROR_VIEWER,
                label: VIEWER_LABELS[VIEWER_TYPES.ERROR_VIEWER],
                id: VIEWER_TYPES.ERROR_VIEWER
            });
        } else {
            logNotification(copy.noErrors);
        }
    }

    private getAccessDataHandler = () => {
        const {
            auth: { accessToken },
            organizationId
        } = this.props;
        const baseUrl = import.meta.env.VITE_IMPOSIUM_BASE;

        this.setState({ totalRendersLoading: true });

        SessionService.getAccessData(accessToken, baseUrl, true, organizationId)
            .then((res) => {
                this.props.setAccessData(res);
                this.setState({ totalRendersLoading: false });
            })
            .catch((e: Error) => {
                console.error(e);
            });
    };

    private getStoriesColumns() {
        return [
            {
                accessor: 'name',
                Header: 'Name',
                width: 115,
                minWidth: 115,
                disableSortBy: false
            },
            {
                accessor: 'total_renders',
                Header: 'Total Renders',
                width: 60,
                minWidth: 60,
                maxWidth: 60,
                disableSortBy: false,
                Cell: (cell: any) => (
                    <StoryTableTotalRendersCell
                        loading={this.state.totalRendersLoading}
                        cell={cell}
                        date_created={false}
                    />
                )
            },
            {
                accessor: 'last_modified_by',
                Header: 'Last Modified By',
                width: 60,
                minWidth: 60,
                maxWidth: 60,
                disableSortBy: true
            },
            {
                accessor: 'date_modified',
                Header: 'Last Modified',
                width: 65,
                minWidth: 65,
                maxWidth: 65,
                disableSortBy: false,
                Cell: (cell: any) => (
                    <AssetsTableDateCell
                        cell={cell}
                        date_created={false}
                    />
                )
            },
            {
                accessor: 'date_created',
                Header: 'Created Date',
                width: 65,
                minWidth: 65,
                maxWidth: 65,
                disableSortBy: false,
                Cell: (cell: any) => (
                    <AssetsTableDateCell
                        cell={cell}
                        date_created={true}
                    />
                )
            }
        ];
    }

    private renderStoryDropdown = () => {
        const {
            story: { id: activeStory, name },
            organizationId,
            editor: { fromCrM },
            access
        } = this.props;

        const { showStoriesDropdown, order, orderDirection } = this.state;

        let currentStory: any;
        let storyMenuInner: JSX.Element = (
            <div className='no-stories-dialog'>{copy.noStoriesFound}</div>
        );
        let storyToggle: JSX.Element = <Spinner />;

        const orgObj = access?.organizations.find((org) => org.id === organizationId);

        if (!fromCrM) {
            if (orgObj && orgObj.stories) {
                const orgStories = orgObj.stories;
                currentStory = orgStories.find((s: any) => s.id === activeStory);
                storyToggle = (
                    <h1
                        className={`story-name`}
                        ref={this.storyToggleRef}
                        onClick={() => {
                            this.setState(
                                {
                                    showStoriesDropdown: !showStoriesDropdown
                                },
                                () => {
                                    if (showStoriesDropdown === false) this.getAccessDataHandler();
                                }
                            );
                        }}>
                        {currentStory ? currentStory.name : name ? name : copy.noStoryDefault}
                    </h1>
                );

                if (orgStories.length !== 0) {
                    const sortBy =
                        order && orderDirection
                            ? [{ id: order, desc: orderDirection === 'desc' }]
                            : undefined;

                    const sortedStories = _.orderBy(orgStories, ['date_modified'], ['desc']);

                    storyMenuInner = (
                        <DataTable
                            columns={this.getStoriesColumns()}
                            data={sortedStories}
                            sortBy={sortBy}
                            noDataText={copy.noStoriesFound}
                            itemsPerPage={orgStories.length}
                            hidePaginator={true}
                            onRowClick={this.changeStory}
                        />
                    );
                }
                return (
                    <>
                        {storyToggle}
                        <ImposiumDropdown
                            key='stories-dropdown'
                            position='bottomleft'
                            show={showStoriesDropdown}
                            toggleRef={this.storyToggleRef}
                            onOutsideClick={() => this.setState({ showStoriesDropdown: false })}>
                            <div className='stories-menu'>{storyMenuInner}</div>
                        </ImposiumDropdown>
                    </>
                );
            }
        } else {
            return (
                <h1
                    className={`story-name disabled`}
                    ref={this.storyToggleRef}>
                    {name}
                </h1>
            );
        }
    };

    private getScene() {
        const {
            story,
            project: { actId, sceneId }
        } = this.props;
        const act = actId ? story.acts[actId] : getFirstAct(story);
        return act.scenes[sceneId];
    }

    public render() {
        const {
            changes: { unsaved, loading },
            publishData: { unpublished_changes },
            story: { id },
            editor: { fromCrM }
        } = this.props;
        const scene = this.getScene();
        const disableSave = !unsaved || disableForEncodingSettings(scene);
        const saveStyle = disableSave ? 'default' : 'bold';
        const saveColor = disableSave ? 'default' : 'primary';

        const unpublishedTooltipId = `imposium-button-${generateUUID()}`;

        const unpublishedChanges = unpublished_changes ? (
            <div
                className='unpublish-changes'
                {...renderTooltipProps(unpublishedTooltipId, copy.unpublished)}>
                {ICON_WARNING}
                {renderTooltip(unpublishedTooltipId, copy.unpublished)}
            </div>
        ) : null;

        const btnSettings = !fromCrM ? (
            <Button
                size='large'
                style='subtle'
                onClick={this.evtHandlers.buttons.settings}>
                {ICON_COG}
            </Button>
        ) : null;

        const btnNewStory = !fromCrM ? (
            <Button
                onClick={this.evtHandlers.buttons.newComp}
                color='primary'
                style='bold'
                size='large'
                tooltip={copy.newComposition}>
                {copy.btnNewProject} {ICON_VIDEOPLUS}
            </Button>
        ) : null;

        return (
            <div className='header'>
                {this.renderStoryDropdown()}
                {btnSettings}
                <div className='button-menu'>{btnNewStory}</div>
                <div className='right-buttons'>
                    <Button
                        color={saveColor}
                        style={saveStyle}
                        size='large'
                        loading={loading}
                        tooltip={copy.tooltipSave}
                        onClick={this.evtHandlers.buttons.save}>
                        {copy.btnSave} {ICON_SAVE}
                    </Button>
                    <Button
                        color='primary'
                        style='bold'
                        size='large'
                        tooltip={copy.tooltipPublish}
                        onClick={this.evtHandlers.buttons.publish}>
                        {copy.btnPublish} {ICON_PUBLISH}
                    </Button>
                    {unpublishedChanges}
                    <PublishStatusIndicator
                        api={api}
                        storyId={id}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccessData: (d) => dispatch(setAccessData(d)),
        updateEditorConfig: (c) => dispatch(updateEditorConfig(c)),
        createStory: (i) => dispatch(createStory(i)),
        saveStory: () => dispatch(saveStory()),
        deleteStory: (i) => dispatch(deleteStory(i)),
        overwriteStory: (i, d) => dispatch(overwriteStory(i, d)),
        addViewer: (i) => dispatch(addViewer(i)),
        storyErrors: () => dispatch(checkStoryForErrors()),
        clearStoryPublishStatus: (a) => dispatch(clearStoryPublishStatus(a)),
        doAssetTableHydration: (i, d) => dispatch(doAssetTableHydration(i, d)),
        resetFilters: () => dispatch(resetFilters()),
        getStoryPublishStatus: (a, s) => dispatch(getStoryPublishStatus(a, s))
    };
};

const mapStateToProps = (state): any => {
    return {
        compositions: state.compositions.present,
        editor: state.editor,
        changes: state.changes,
        project: state.project,
        story: state.story,
        errors: state.errors,
        auth: state.auth,
        publishData: state.publish,
        access: state.access
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftHeader);
