import { Button, Spinner } from '@imposium-hub/components';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { registerAllValidators } from 'handsontable/registry';
import { DatasetRowActions } from './DatasetRowActions';
import { previewStoryClicked } from '../../util/ui';
import { MEDIA_VARIABLE_TYPES, VARIABLE_TYPES } from '../../constants/story';
import {
    getCellSource,
    getCellType,
    moveColumnsHandler,
    moveRowsHandler
} from '../../util/dataset';
import { MAX_DATASET_LENGTH } from '../../constants/dataset';
import { useEffect, useRef, useState } from 'react';
import { DatasetColumnModal } from './DatasetColumnModal';
import { ICON_PLUS, TURN_LEFT_REG, TURN_LEFT_SOLID } from '../../constants/icons';
import { HOT_KEY } from '../../constants/app';
import { DatasetMediaPreview } from './DatasetMediaPreview';

registerAllModules();
registerAllValidators();

export const DatasetCSVEditor = ({
    activeDataset,
    onChangeData,
    onAddRow,
    onDuplicateRow,
    loading,
    onRemoveColumns,
    onMoveColumns,
    onColumnResize,
    onAddColumn,
    activeComposition,
    checkingForErrors,
    onShowErrors,
    onRemoveRows,
    onMoveRows,
    variables,
    onPreview,
    height,
    width,
    errors
}) => {
    if (loading) {
        return (
            <div className='dataset-loading-wrapper'>
                <Spinner />
            </div>
        );
    } else if (!activeDataset) {
        return null;
    }

    useEffect(() => {
        if (activeDataset.column_widths && wordWraps.length === 0) {
            const wordWrapsArray = [];
            for (const w of activeDataset.column_widths) {
                if (w) {
                    wordWrapsArray.push(false);
                }
            }
            setWordWraps(wordWrapsArray);
        }
    }, [activeDataset.column_widths]);

    const [addingColumn, setAddingColumn] = useState(false);
    const [previewConfig, setPreviewConfig] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [activeCol, setActiveCol] = useState(null);
    const [wordWraps, setWordWraps] = useState<any[]>([]);
    const [colIndex, setColIndex] = useState<number>();

    useEffect(() => {
        const eles = document.getElementsByClassName(`wordWrapBtn ${colIndex}`);
        if (wordWraps[colIndex] !== undefined && eles.length > 0) {
            for (const ele of eles) {
                ele.innerHTML = wordWraps[colIndex] ? TURN_LEFT_SOLID : TURN_LEFT_REG;
            }
        }
    }, [JSON.stringify(wordWraps)]);

    const hot: any = useRef();

    const onBeforeHotChange = (changes, source) => {
        onChangeData(activeDataset.id, changes);
        return false;
    };

    const onBeforeAddRow = (index, amount, source) => {
        const idx = source === 'ContextMenu.rowAbove' ? index : index + 1;
        onAddRow(activeDataset.id, idx, 1);
        return false;
    };

    const onBeforeRemoveRow = (index, amount, rows) => {
        onRemoveRows(activeDataset.id, rows);
        return false;
    };

    const onBeforeColumnMove = (moveCol, finalIndex, dropIndex) => {
        const { id } = activeDataset;
        const {
            current: {
                hotInstance: { selectColumns }
            }
        } = hot;
        const newDataset = moveColumnsHandler(moveCol, finalIndex, activeDataset);
        onMoveColumns(id, newDataset);
        onColumnResize(id, newDataset.column_widths);
        const endIndex = finalIndex + moveCol.length - 1;
        selectColumns(finalIndex, endIndex);
        return false;
    };

    const onBeforeRowMove = (moveRow, finalIndex, dropIndex) => {
        // If the rows haven't moved. don't do anything
        if (moveRow[0] === finalIndex) {
            return;
        }
        const { id } = activeDataset;
        const newDataset = moveRowsHandler(moveRow, finalIndex, activeDataset);
        onMoveRows(id, newDataset);

        const {
            current: {
                hotInstance: { selectRows }
            }
        } = hot;

        const endIndex = finalIndex + moveRow.length - 1;
        selectRows(finalIndex, endIndex);
        return false;
    };

    const onBeforeColumnResize = (newSize, column, isDoubleClick) => {
        const index = column - 1;
        const newActiveDataset = { ...activeDataset };
        const { column_widths, id, data } = newActiveDataset;
        let columns = [];
        for (const idx in data[0]) {
            if (idx) {
                columns[idx] = 100;
            }
        }
        if (column_widths) {
            columns = column_widths;
        }

        columns[index] = newSize;

        onColumnResize(id, columns);
    };

    const onBeforeKeyDown = (e) => {
        const cellArray = hot.current.hotInstance.getSelectedLast();
        if (cellArray) {
            const colIdx = cellArray[1] - 1;
            const header = activeDataset.headers[colIdx];
            const variable = variables[header];
            if (variable && variable.type === VARIABLE_TYPES.NUMBER) {
                const regex = /[^\d.]+/g;
                const key: string = e.key;
                if (key.length === 1 && regex.test(key)) {
                    e.preventDefault();
                }
            }
        }
    };

    const isLinkedToVariable = (columnIndex) => {
        const header = activeDataset.headers[columnIndex];
        if (variables[header]) {
            return true;
        }
        return false;
    };

    const getColumnHeader = (index) => {
        const header = activeDataset.headers[index];
        if (isLinkedToVariable(index)) {
            return `{{${header}}}`;
        } else {
            return header;
        }
    };

    const onRowRender = (e, rowIndex) => {
        const data = activeDataset.data[rowIndex];
        const rowInventory = {};
        for (const header in activeDataset.headers) {
            if (header) {
                const value = data[header];
                const id = activeDataset.headers[header];
                rowInventory[id] = value;
            }
        }
        previewStoryClicked(e, rowInventory);
    };

    const onRowPreview = (e, rowIndex) => {
        const data = activeDataset.data[rowIndex];
        const items = [];
        for (const header in activeDataset.headers) {
            if (header) {
                const value = data[header];
                const id = activeDataset.headers[header];
                const variable = variables[id];
                if (variable) {
                    const newConfig = { ...variables[id] };
                    const previewItem = { ...newConfig.previewItem };
                    const type = newConfig.type.toLowerCase();
                    if (
                        type === VARIABLE_TYPES.IMAGE ||
                        type === VARIABLE_TYPES.VIDEO ||
                        type === VARIABLE_TYPES.AUDIO
                    ) {
                        previewItem.url = value;
                    } else {
                        previewItem.src = value;
                    }
                    newConfig['previewItem'] = previewItem;
                    items.push(newConfig);
                }
                onPreview(items);
            }
        }
    };

    const onDeleteColumn = (key, selection, clickEvent) => {
        const rangeArray = [];
        for (const colArray of selection) {
            const startIndex = colArray.start.col;
            const endIndex = colArray.end.col;
            for (let i = startIndex; i <= endIndex; i++) {
                rangeArray.push(i);
            }
        }
        onRemoveColumns(activeDataset.id, rangeArray);
    };

    const addColumnContinue = (columnName) => {
        if (columnName && columnName.length > 0) {
            setAddingColumn(false);
            onAddColumn(activeDataset.id, columnName);
        }
    };

    const getContextMenu = () => {
        const menu: any = {
            items: {
                clear_column: {
                    disabled() {
                        return this.getSelectedLast()[0] !== -1; // Only show this option if the user is right clicking on a column header
                    }
                },
                delete: {
                    name: 'Delete Column',
                    callback: onDeleteColumn,
                    disabled() {
                        return this.getSelectedLast()[0] !== -1; // Only show this option if the user is right clicking on a column header
                    },
                    hidden() {
                        const index = this.getSelectedLast()[1] - 1;
                        const header = activeDataset.headers[index];
                        const isVariable = variables[header] !== undefined;
                        return isVariable;
                    }
                },
                add_col: {
                    name: 'Add Column',
                    callback: () => setAddingColumn(true),
                    hidden() {
                        return this.getSelectedLast()[0] !== -1; // Only show this option if the user is right clicking on a column header
                    }
                },
                cut: {},
                copy: {},
                remove_row: {
                    disabled() {
                        const selected = this.getSelectedLast();
                        const selectedRows = Math.abs(selected[2] - selected[0]) + 1;
                        if (selectedRows === activeDataset.data.length) {
                            return true;
                        }
                        return false;
                    }
                }
            }
        };

        if (activeDataset.data.length < MAX_DATASET_LENGTH) {
            menu.items['row_above'] = {};
            menu.items['rows_above'] = {
                callback() {
                    const selected = this.getSelectedLast();
                    const rowsSelected = Math.abs(selected[2] - selected[0]) + 1;
                    onAddRow(activeDataset.id, selected[0], rowsSelected);
                },
                hidden() {
                    const selected = this.getSelectedLast();
                    if (selected[0] === -1) {
                        return true;
                    }
                    const rowsSelected = Math.abs(selected[2] - selected[0]);
                    return rowsSelected < 1; // Only show if more than 1 row is selected
                },
                name() {
                    const selected = this.getSelectedLast();
                    const rowsSelected = Math.abs(selected[2] - selected[0]) + 1;
                    return `Insert (${rowsSelected}) rows above`;
                }
            };
            menu.items['row_below'] = {};
            menu.items['rows_below'] = {
                callback() {
                    const selected = this.getSelectedLast();
                    const rowsSelected = Math.abs(selected[2] - selected[0]) + 1;
                    onAddRow(activeDataset.id, selected[2] + 1, rowsSelected);
                },
                hidden() {
                    const selected = this.getSelectedLast();
                    if (selected[0] === -1) {
                        return true;
                    }
                    const rowsSelected = Math.abs(selected[2] - selected[0]);
                    return rowsSelected < 1; // Only show if more than 1 row is selected
                },
                name() {
                    const selected = this.getSelectedLast();
                    const rowsSelected = Math.abs(selected[2] - selected[0]) + 1;
                    return `Insert (${rowsSelected}) rows below`;
                }
            };
            menu.items['duplicate_row'] = {
                callback() {
                    const selected = this.getSelectedLast();
                    onDuplicateRow(activeDataset.id, selected[0]);
                },
                hidden() {
                    const selected = this.getSelectedLast();
                    if (selected[1] !== -1) {
                        return true;
                    }
                    return false;
                },
                name: 'Duplicate Row'
            };
        }

        return menu;
    };

    const onAddRowButton = () => {
        onAddRow(activeDataset.id, activeDataset.data.length, 1);
        setTimeout(() => {
            hot.current.hotInstance.scrollViewportTo(activeDataset.data.length, 0);
        }, 25);
    };

    let btnAddRow = null;
    if (activeDataset.data.length < MAX_DATASET_LENGTH) {
        btnAddRow = (
            <Button
                color='primary'
                style='bold'
                onClick={onAddRowButton}>
                {ICON_PLUS} Add row
            </Button>
        );
    }

    const getColWidths = () => {
        const { column_widths } = activeDataset;
        if (column_widths) {
            return column_widths;
        } else {
            return '100px';
        }
    };

    const onHidePreview = () => {
        setPreviewConfig(null);
    };

    const onAfterSelection = (row, column, row2, column2) => {
        // If it is a single cell, and it's a media cell, show the preview
        if (row === row2 && column === column2) {
            if (row !== activeRow || column !== activeCol) {
                const data = activeDataset.data[row][column - 1];
                const header = activeDataset.headers[column - 1];
                const variable = variables[header];
                if (variable && MEDIA_VARIABLE_TYPES.indexOf(variable.type) !== -1 && data) {
                    const cell = hot.current.hotInstance.getCell(row, column);
                    setPreviewConfig({
                        type: variable.type,
                        data,
                        cell
                    });
                } else {
                    setPreviewConfig(null);
                }
                setActiveRow(row);
                setActiveCol(column);
            }
        } else {
            afterDeselect();
        }
    };

    const afterDeselect = () => {
        setActiveCol(null);
        setActiveRow(null);
        clearPreview();
    };

    const clearPreview = () => {
        setPreviewConfig(null);
    };

    const HOTWidth = `${width}px`;
    const HOTHeight = `${height}px`;

    // Have to do this so we don't need to click to select the cell, then click to hit the button a second time
    const onAfterOnCellMouseDown = (e, coords) => {
        const { className } = e.target;
        if (className && typeof className === 'string') {
            if (className.indexOf('btn-render-row') !== -1) {
                onRowRender(e, coords.row);
            } else if (className.indexOf('btn-preview-row') !== -1) {
                onRowPreview(e, coords.row);
            } else if (className.indexOf('btn-error-indicator') !== -1) {
                onShowErrors(coords.row);
            } else if (className.indexOf('wordWrapBtn') !== -1) {
                onWordWrap(coords.col - 1);
            }
        }
    };

    const getKeyFromHeaders = () => {
        let key = '';
        activeDataset.headers.map((header, i) => {
            key += `${header}-${i} `;
        });
        return key;
    };

    const onWordWrap = (index) => {
        const updateWordWraps = wordWraps;
        const update = !updateWordWraps[index];
        updateWordWraps[index] = update;
        setWordWraps(updateWordWraps);
        setColIndex(index);
    };

    return (
        <>
            {addingColumn && (
                <DatasetColumnModal
                    headers={activeDataset.headers}
                    onClose={() => setAddingColumn(false)}
                    onContinue={addColumnContinue}
                />
            )}
            {previewConfig && (
                <DatasetMediaPreview
                    config={previewConfig}
                    onClose={onHidePreview}
                />
            )}

            <div
                className='hot-wrapper'
                style={{ width: HOTWidth, height: HOTHeight }}>
                <HotTable
                    key={getKeyFromHeaders()}
                    width={HOTWidth}
                    height={HOTHeight}
                    data={activeDataset.data}
                    renderAllRows={false}
                    selectionMode={'range'}
                    afterSelectionEnd={onAfterSelection}
                    afterDeselect={afterDeselect}
                    afterBeginEditing={clearPreview}
                    beforeChange={onBeforeHotChange}
                    beforeCreateRow={onBeforeAddRow}
                    beforeRemoveRow={onBeforeRemoveRow}
                    beforeColumnMove={onBeforeColumnMove}
                    beforeRowMove={onBeforeRowMove}
                    beforeKeyDown={onBeforeKeyDown}
                    beforeColumnResize={onBeforeColumnResize}
                    afterOnCellMouseDown={onAfterOnCellMouseDown}
                    dropdownMenu={false}
                    rowHeaders={true}
                    manualRowMove={true}
                    manualColumnMove={true}
                    contextMenu={getContextMenu()}
                    autoColumnSize={false}
                    autoRowSize={false}
                    colWidths={getColWidths()}
                    afterGetColHeader={(col, TH) => {
                        if (col !== 0 && col !== -1) {
                            const index = col - 1;
                            if (TH.querySelector(`span.wordWrapBtn`)) {
                                return;
                            }
                            const relative = TH.querySelector('.relative');
                            const btn = document.createElement('span');

                            btn.className = `wordWrapBtn ${index}`;
                            btn.innerHTML = TURN_LEFT_REG;

                            relative.appendChild(btn);
                        }
                    }}
                    manualColumnResize={true}
                    ref={hot}
                    licenseKey={HOT_KEY}>
                    <HotColumn
                        key='actions'
                        title={'Actions'}
                        width={200}
                        allowEmpty={true}
                        readOnly={true}
                        data={null}>
                        <DatasetRowActions
                            errors={errors}
                            activeComposition={activeComposition}
                            activeDataset={activeDataset}
                            variables={variables}
                            hot-renderer
                            checkingForErrors={checkingForErrors}
                        />
                    </HotColumn>
                    {activeDataset.headers.map((header, index) => {
                        const { column_widths } = activeDataset;
                        const variable = variables[header];
                        const type = getCellType(variable);
                        const source = getCellSource(variable);

                        let columnWidths = 100;
                        let wordWrap = false;

                        if (column_widths && column_widths[index]) {
                            columnWidths = column_widths[index];
                        }

                        if (wordWraps && wordWraps[index]) {
                            wordWrap = wordWraps[index];
                        }

                        return (
                            <HotColumn
                                data={index}
                                width={columnWidths}
                                key={header}
                                source={source}
                                type={type}
                                wordWrap={wordWrap}
                                title={getColumnHeader(index)}></HotColumn>
                        );
                    })}
                </HotTable>
            </div>
            <div className='dataset-lower-buttons'>{btnAddRow}</div>
        </>
    );
};
